import React, { useContext } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useSpring, animated, to } from 'react-spring';

import HeaderContext from '@/context/HeaderContext';

import Heading from '@/atoms/Heading';

const Logo = styled(Heading).attrs({ h: 3 })`
  pointer-events: all;
`;

export default props => {
  const [{ logoNumber }] = useContext(HeaderContext);
  const { animatedNumber } = useSpring({ animatedNumber: logoNumber });

  return (
    <Logo as="h1" {...props}>
      <Link to="/">
        NR
        <animated.span>{to([animatedNumber], n => n.toFixed(0))}</animated.span>
      </Link>
    </Logo>
  );
};
