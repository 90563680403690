import React from 'react';
import { ThemeProvider } from 'styled-components';

import { HeaderProvider } from '@/context/HeaderContext';

import Head from '@/components/Head';
import Header from '@/components/Header';
import PageTransition from '@/components/PageTransition';

import GlobalStyles from '@/styles/global';
import theme from '@/styles/theme';
import { defaultTransition } from '@/styles/pageTransitions';

export default ({ children, location, pageContext }) => {
  const headerColor =
    pageContext.backgroundColor === 'black' ? 'white' : 'black';

  const isGlobalLandingPage =
    pageContext.isGlobalLandingPage ||
    process.env.GATSBY_GLOBAL_LANDING_PAGE === 1;

  return (
    <>
      <Head />
      <GlobalStyles background={pageContext.backgroundColor} />

      <ThemeProvider theme={theme}>
        {isGlobalLandingPage ? (
          children
        ) : (
          <HeaderProvider location={location}>
            <Header location={location} color={headerColor} />

            <PageTransition.Provider location={location} {...defaultTransition}>
              <PageTransition.Views>{children}</PageTransition.Views>
            </PageTransition.Provider>
          </HeaderProvider>
        )}
      </ThemeProvider>
    </>
  );
};
