const rem = val => `${val / 16}rem`;

export default {
  scale: 1, // (in rem) => scale(1) = 16px
  breakpoints: ['36em', '48em', '62em', '75em'],
  breakpointNames: ['xs', 'sm', 'md', 'lg', 'xl'],

  grid: {
    gutter: [0.625, 0.625, 1.125],
    outerMargin: [1, 1, 2],
    // maxWidth: 100,
  },

  spacing: {
    xlarge: [11, 11, 11, 14],
    large: [8, 8, 8, 10],
    medium: [3.5, 3.5, 6.5],

    // where the content starts from top of the page
    contentTop: [10.5, 10.5, 14],

    // the lh-spacings match the line-heights of the corresponding Headings
    lhH1: [rem(54), rem(54), rem(54), rem(100)],
    lhH2: [rem(40), rem(40), rem(40), rem(62)],
    lhH3: [rem(30), rem(30), rem(30), rem(42)],
    lhPL: [rem(22), rem(22), rem(22), rem(32)],
    lhP: [rem(22), rem(22), rem(22), rem(24)],
    lhPS: [rem(18), rem(18), rem(18), rem(24)],
    lhPXS: [rem(14), rem(14), rem(14), rem(14)],
  },

  colors: {
    white: '#fff',
    black: '#000',
    grayDark: '#afafaf',
    gray: '#bdbdbd',
    grayLight: '#fafafa',
  },

  colorSchemes: {
    black: {
      title: 'black',
      text: 'gray',
      hover: 'gray',
    },
    white: {
      title: 'white',
      text: 'grayDark',
      hover: 'grayDark',
    },
    gray: {
      hover: 'black',
    },
    grayLight: {
      hover: 'white',
    },
    grayDark: {
      hover: 'black',
    },
  },

  font: {
    sans: '"CentraNo2", sans-serif',
  },
};
