import React, { createContext, useReducer, useState, useEffect } from 'react';

const defaultState = {
  temporaryColor: null,
  logoNumber: 2154,
};

const HeaderContext = createContext(defaultState);

export const HeaderProvider = ({ location, ...props }) => {
  const [lastLocation, setLastLocation] = useState(location.key);

  const [state, dispatch] = useReducer((prevState, { type, value }) => {
    switch (type) {
      case 'SET_COLOR':
        return {
          ...prevState,
          temporaryColor: value,
        };
      case 'SET_LOGO_NUMBER':
        return {
          ...prevState,
          logoNumber: value || defaultState.logoNumber,
        };
      case 'RESET':
        return defaultState;
      default:
        return prevState;
    }
  }, defaultState);

  // reset context when location changes
  useEffect(() => {
    if (location.key !== lastLocation) {
      dispatch({ type: 'RESET' });
      setLastLocation(location.key);
    }
  }, [location.key, lastLocation, dispatch]);

  return <HeaderContext.Provider value={[state, dispatch]} {...props} />;
};

export default HeaderContext;
