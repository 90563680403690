import React, { useState, useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import NavItem from './NavItem';
import Box from '@/atoms/Box';

import { mapToSingleObject } from '@/utils/helpers';

const Nav = styled(Box)`
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`;

const isActive = (itemPath, locationPath) =>
  itemPath === '/' ? locationPath === '/' : locationPath.startsWith(itemPath);

export default ({ location, handleClick, ...props }) => {
  const [activeItem, setActiveItem] = useState(-1);

  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettingsNavigation {
        edges {
          node {
            frontpage {
              id
            }
            mainNavigationItems {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const navItems = useMemo(() => {
    const settings = mapToSingleObject(data);
    const items = settings.mainNavigationItems || [];

    return items.map(({ id, slug, ...item }) => {
      const path =
        id === data.edges[0].node.frontpage.id ? '/' : `/${slug.current}`;

      return {
        path,
        ...item,
      };
    });
  }, [data]);

  useEffect(() => {
    if (location.pathname) {
      setActiveItem(
        navItems.findIndex(item => isActive(item.path, location.pathname))
      );
    }
  }, [navItems, location.pathname]);

  return (
    <Nav as="nav" role="navigation" pb={[4, 4, 0]} pr={[0, 0, 8]} {...props}>
      <ul>
        {navItems.map((item, i) => (
          <NavItem
            key={item.path}
            isActive={i === activeItem}
            handleClick={handleClick}
            {...item}
          />
        ))}
      </ul>
    </Nav>
  );
};
