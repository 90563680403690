import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import HeaderContext from '@/context/HeaderContext';

import useLockBodyScroll from '@/hooks/useLockBodyScroll';
import useResize from '@/hooks/useResize';

import Logo from './Logo';
import ToggleButton from './ToggleButton';
import Menu from './Menu';
import Grid from '@/atoms/Grid';

import theme from '@/styles/theme';

const BREAKPOINT_MD = parseInt(theme.breakpoints[2].replace('em', ''), 10) * 16;

const Header = styled(Grid.Container)`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: color 0.25s ease-out;
`;

export default ({ location, color = 'black', ...props }) => {
  const [{ temporaryColor }] = useContext(HeaderContext);
  const [isExpanded, setExpanded] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const currentColor = isExpanded ? 'black' : temporaryColor || color;

  const setViewport = () => {
    if (typeof window === 'undefined') return;

    if (window.innerWidth < BREAKPOINT_MD) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  // check if we're on desktop or mobile
  useResize(setViewport);
  useEffect(setViewport, []);

  // lock body scroll if mobile overlay is active
  useLockBodyScroll(isMobile && isExpanded);

  const toggleMenu = () => setExpanded(!isExpanded);
  const closeMenu = () => setExpanded(false);

  return (
    <Header
      $display="flex"
      $justifyContent="space-between"
      $alignItems="center"
      $color={currentColor}
      pt={[0.5, 0.5, 1.25]}
      {...props}
    >
      <Logo onClick={closeMenu} />

      <Menu
        isVisible={isExpanded}
        location={location}
        handleClose={closeMenu}
      />

      <ToggleButton
        icon={isExpanded ? 'close' : 'hamburger'}
        onClick={toggleMenu}
        $color={currentColor}
        mr={[-0.5, -0.5, -0.25]}
      />
    </Header>
  );
};
