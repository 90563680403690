export const onClientEntry = async () => {
  // polyfill intersetion observer
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }

  // polyfill timezone support
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  if (typeof timeZone === 'undefined' || timeZone.length === 0) {
    await import('date-time-format-timezone');
  }
};

/**
 * Scroll position is handled through page transition component
 */
export const shouldUpdateScroll = () => {
  return false;
};
