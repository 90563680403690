import React from 'react';

import { useTransitionStore } from './Provider';

export default ({
  to,
  enter = null,
  usual = null,
  leave = null,
  preventScrollUpdate = false,
  enterDelay,
  ...props
}) => {
  const [, dispatch] = useTransitionStore();

  const onClick = e => {
    e.preventDefault();

    dispatch({
      type: 'NAVIGATE',
      to,
      enter,
      usual,
      leave,
      preventScrollUpdate,
    });
  };

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a href={to} onClick={onClick} {...props} />;
};
