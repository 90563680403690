/**
 * Always return array
 */
export const forceArray = possibleArray =>
  Array.isArray(possibleArray) ? possibleArray : [possibleArray];

/**
 * GraphQL Edges->Node Helper
 */
export const mapEdgesToNodes = data => {
  if (!data.edges) return [];

  return data.edges.map(edge => edge.node);
};

/**
 * GraphQL Helper for data that has a single edge (e.g. site settings)
 */
export const mapToSingleObject = data => {
  const nodes = mapEdgesToNodes(data);

  return nodes[0] || {};
};

/**
 * Verify email addresses
 * https://tylermcginnis.com/validate-email-address-javascript/
 */
export const validateEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

/**
 * Debounce
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */
export const debounce = (func, wait = 200, immediate = false) => {
  let timeout;

  return () => {
    const context = this;
    // eslint-disable-next-line no-undef
    const args = arguments;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

/**
 * Shuffle Array
 */
export const shuffle = list => {
  const listShuffled = list;
  let ctr = listShuffled.length;
  let temp;
  let index;

  // While there are elements in the array
  while (ctr > 0) {
    // Pick a random index
    index = Math.floor(Math.random() * ctr);
    // Decrease ctr by 1
    ctr -= 1;
    // And swap the last element with it
    temp = listShuffled[ctr];
    listShuffled[ctr] = listShuffled[index];
    listShuffled[index] = temp;
  }

  return listShuffled;
};
