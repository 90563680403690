import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Text from '@/atoms/Text';

import { media } from '@/styles/utils';
import theme from '@/styles/theme';

const Item = styled(Text)`
  width: 100%;

  :last-child {
    margin-right: 0;
  }

  ${media.md`
    width: auto;
  `}

  a {
    transition: color 0.1s ease-out;

    ${props =>
      props.isActive &&
      `
      color: ${theme.colors.black};
    `};

    :hover,
    :focus {
      color: ${theme.colors.black};
    }
  }
`;

export default ({
  title,
  path,
  isActive,
  isNavHovered,
  handleClick,
  ...props
}) => (
  <Item
    as="li"
    t="menuItem"
    isActive={isActive}
    $color="gray"
    mr={[0, 0, 2]}
    {...props}
  >
    <Link to={path} onClick={handleClick}>
      {title}
    </Link>
  </Item>
);
