import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Box from '@/atoms/Box';
import Text from '@/atoms/Text';
import WithArrow from '@/atoms/WithArrow';

import { mapToSingleObject } from '@/utils/helpers';

const List = styled(Box).attrs({ as: 'ul' })`
  list-style: none;
`;

export default props => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettingsInformation {
        edges {
          node {
            contactLinksMobile {
              label
              url
            }
          }
        }
      }
    }
  `);

  const { contactLinksMobile } = mapToSingleObject(data);

  return (
    <List {...props}>
      {contactLinksMobile.map(link => (
        <Text as="li" key={link.label}>
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            <WithArrow>{link.label}</WithArrow>
          </a>
        </Text>
      ))}
    </List>
  );
};
