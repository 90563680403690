import { css } from 'styled-components';

import { media, rem } from '@/styles/utils';

const reset = css`
  font-weight: normal;
  text-decoration: none;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const h1 = css`
  ${reset}
  font-size: ${rem(44)};
  line-height: ${rem(54)};

  ${media.lg`
    font-size: ${rem(84)};
    line-height: ${rem(100)};
  `}
`;

export const h2 = css`
  ${reset}
  font-size: ${rem(30)};
  line-height: ${rem(40)};

  ${media.lg`
    font-size: ${rem(48)};
    line-height: ${rem(62)};
  `}
`;

export const h3 = css`
  ${reset}
  font-size: ${rem(22)};
  line-height: ${rem(30)};

  ${media.lg`
    font-size: ${rem(32)};
    line-height: ${rem(42)};
  `}
`;

export const h4 = css`
  ${reset}
  font-size: ${rem(20)};
  line-height: ${rem(28)};

  ${media.lg`
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}
`;

export const pLarge = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(22)};

  ${media.lg`
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}
`;

export const p = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(22)};

  ${media.lg`
    font-size: ${rem(18)};
    line-height: ${rem(24)};
  `}
`;

export const pSmall = css`
  ${reset}
  font-size: ${rem(14)};
  line-height: ${rem(18)};

  ${media.lg`
    font-size: ${rem(18)};
    line-height: ${rem(24)};
  `}
`;

export const pXSmall = css`
  ${reset}
  font-size: ${rem(12)};
  line-height: ${rem(14)};
`;

export const menuItem = css`
  ${reset}
  font-size: ${rem(56)};
  line-height: ${rem(64)};

  ${media.md`
    font-size: ${rem(18)};
    line-height: ${rem(24)};
  `}

  ${media.lg`
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}
`;

export const featureTitle = css`
  ${reset}
  font-size: ${rem(30)};
  line-height: ${rem(40)};

  ${media.lg`
    font-size: ${rem(32)};
    line-height: ${rem(42)};
  `}
`;

export const displayHeadline = css`
  ${reset}
  font-size: ${rem(44)};
  line-height: ${rem(54)};

  ${media.lg`
    font-size: ${rem(84)};
    line-height: ${rem(100)};
  `}

  ${media.xl`
    font-size: ${rem(100)};
    line-height: ${rem(120)};
  `}
`;
