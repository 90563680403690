import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import theme from '@/styles/theme';
import { defaultTransition } from '@/styles/pageTransitions';

const easeInOutQuad = 'cubic-bezier(0.45, 0, 0.55, 1)';

export default createGlobalStyle`
  ${styledNormalize}

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  html {
    min-height: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    font-family: ${theme.font.sans};
    font-size: 16px;
    font-weight: normal;
    color: ${theme.colors.black};
    background: ${props => theme.colors[props.background]};
  transition: background-color ${
    defaultTransition.enter.config.duration
  }ms ${easeInOutQuad};
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    ::-moz-selection {
      background: ${theme.colors.gray};
      color: ${theme.colors.black};
    }
    ::selection {
      background: ${theme.colors.gray};
      color: ${theme.colors.black};
    }
  }
`;
