import React, { createContext, useContext, useEffect, useReducer } from 'react';

import reducer from './reducer';

export const TransitionContext = createContext();

export const TransitionProvider = ({
  location = {},
  enterDelay = 500,
  enter = { opacity: 0, config: 'stiff' },
  usual = { opacity: 1, config: 'stiff' },
  leave = { opacity: 0, config: 'stiff' },
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    currentLocation: { key: undefined },
    prevLocation: null,
    views: [],
    enterQueue: [],
    preventScrollUpdate: false,
    enterDelay,
    enter,
    usual,
    leave,
    hasEnterStarted: false,
    hasEntered: false,
    resetScrollPosition: true,
  });

  useEffect(() => {
    dispatch({
      type: 'UPDATE_LOCATION',
      location,
    });
  }, [location]);

  return (
    <TransitionContext.Provider value={[state, dispatch]}>
      {children}
    </TransitionContext.Provider>
  );
};

export const useTransitionStore = () => useContext(TransitionContext);
