import styled from 'styled-components';

import IconButton from '@/atoms/IconButton';

import theme from '@/styles/theme';
import { scale, media } from '@/styles/utils';

export default styled(IconButton)`
  pointer-events: all;

  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - ${scale(0.625)});
    top: calc(50%);
    width: ${scale(1.25)};
    height: 1px;
    background: ${props => theme.colors[props.$color]};
    transition: transform 0.2s ease-out, background-color 0.25s ease-out;
    transform-origin: center center;

    ${media.md`
      left: calc(50% - ${scale(0.75)});
      width: ${scale(1.5)};
    `}
  }

  ::before {
    ${props =>
      props.icon === 'hamburger' &&
      `
      transform: scale(1.25) translateY(${scale(-0.25)});
    `}

    ${props =>
      props.icon === 'close' &&
      `
      transform: scale(1.25) rotate(135deg);
    `}
  }

  ::after {
    ${props =>
      props.icon === 'hamburger' &&
      `
      transform: scale(1.25) translateY(${scale(0.25)});
    `}

    ${props =>
      props.icon === 'close' &&
      `
      transform: scale(1.25) rotate(-135deg);
    `}
  }
`;
