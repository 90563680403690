import React, { useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

import { useTransitionStore } from './Provider';

const Container = styled.div`
  position: ${props => props.$position};
  top: 0;
  width: 100%;
`;

const View = styled(animated.div)`
  width: 100%;
`;

export default ({
  view,
  action,
  enterOverride = null,
  usualOverride = null,
  leaveOverride = null,
  preventScrollUpdate = false,
}) => {
  const containerRef = useRef(null);
  const viewRef = useRef(null);
  const [
    {
      enter: enterDefault,
      usual: usualDefault,
      leave: leaveDefault,
      hasEntered,
    },
    dispatch,
  ] = useTransitionStore();

  const enter = enterOverride || enterDefault;
  const usual = usualOverride || usualDefault;
  const leave = leaveOverride || leaveDefault;

  const [springProps, setSpring] = useSpring(() =>
    action === 'MOUNT'
      ? usual
      : {
          opacity: enter.opacity,
          transform: enter.transform,
        }
  );

  useEffect(() => {
    if (action === 'ENTER') {
      // a new view that is entering the stage
      setSpring({
        ...usual,
        config: enter.config,
        onStart: null,
        onRest: () => {
          dispatch({
            type: 'HAS_ENTERED',
            locationKey: view.props.location.key,
          });
        },
      });
    } else if (action === 'LEAVE') {
      // an existing view that has to leave the stage
      setSpring({
        ...leave,
        config: leave.config,
        onStart: null,
        onRest: null,
      });
    } else if (action === 'MOUNT') {
      // the initial mount
      setSpring({
        ...usual,
        config: enter.config,
        onStart: null,
        onRest: null,
      });
    }
  }, [
    action,
    enter,
    usual,
    leave,
    setSpring,
    dispatch,
    view.props.location.key,
  ]);

  useEffect(() => {
    if (hasEntered) {
      containerRef.current.style.position = 'relative';
    }
  }, [hasEntered, containerRef]);

  return (
    <Container
      ref={containerRef}
      $position={preventScrollUpdate ? 'absolute' : 'fixed'}
    >
      <View ref={viewRef} style={springProps}>
        {view}
      </View>
    </Container>
  );
};
