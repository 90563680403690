import React from 'react';
import styled from 'styled-components';

import useViewportHeight from '@/hooks/useViewportHeight';

import Navigation from './Navigation';
import MobileFooter from './MobileFooter';
import Box from '@/atoms/Box';

import { media, scale } from '@/styles/utils';
import theme from '@/styles/theme';

const Menu = styled(Box)`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => (props.$vh ? `${props.$vh}px` : '100vh')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  pointer-events: ${props => (props.isVisible ? 'all' : 'none')};
  transition: opacity 0.35s ease-out;

  ${media.md`
    height: ${scale(5.125)};
  `}
`;

export default ({ isVisible, location, handleClose, ...props }) => {
  const [viewportHeight] = useViewportHeight();

  return (
    <Menu
      isVisible={isVisible}
      $display="flex"
      $flexDirection="column"
      $justifyContent={['space-between', 'space-between', 'center']}
      $vh={viewportHeight}
      px={theme.grid.outerMargin}
      pt={[theme.spacing.contentTop[0], theme.spacing.contentTop[1], 0]}
      pb={[0.75, 0.75, 0]}
      bg={['grayLight', 'grayLight', 'white']}
      {...props}
    >
      <Navigation location={location} handleClick={handleClose} />

      <MobileFooter $display={['block', 'block', 'none']} />
    </Menu>
  );
};
