import { useEffect, useState } from 'react';

// workaround for mobile devices calculating 100vh without browser chrome
export default () => {
  const [viewportHeight, setViewportHeight] = useState(undefined);

  useEffect(() => {
    let timeout;

    const setHeight = () => {
      const height =
        typeof window.visualViewport !== 'undefined'
          ? window.visualViewport.height
          : window.innerHeight;

      setViewportHeight(Math.round(height));
    };

    const handleResize = () => {
      // timeout is fix for orientation change not working on iOS Chrome
      timeout = setTimeout(setHeight, 100);
    };

    window.addEventListener('resize', handleResize);
    setHeight();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.clearTimeout(timeout);
    };
  }, []);

  return [viewportHeight];
};
