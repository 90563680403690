import { useEffect } from 'react';

export default onResize => {
  useEffect(() => {
    let timeout;

    const handleResize = () => {
      // timeout is fix for orientation change not working on iOS Chrome
      timeout = setTimeout(onResize, 100);
    };

    window.addEventListener('resize', handleResize);
    onResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.clearTimeout(timeout);
    };
  }, [onResize]);
};
