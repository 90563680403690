import CentraNo2BookWoff1 from '@/assets/fonts/CentraNo2-Book.woff';
import CentraNo2BookWoff2 from '@/assets/fonts/CentraNo2-Book.woff2';

export default `
  @font-face {
    font-family: 'CentraNo2';
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    src: url(${CentraNo2BookWoff2}) format('woff2'),
      url(${CentraNo2BookWoff1}) format('woff');
  }
`;
