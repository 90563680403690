import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';

import {
  h1,
  h2,
  h3,
  pLarge,
  p,
  pSmall,
  pXSmall,
  menuItem,
  featureTitle,
  displayHeadline,
} from '@/styles/typography';

export default styled(Box).attrs(props => ({
  as: props.as || 'p',
  t: props.t || 0,
  whiteSpace: props.whiteSpace || 'normal',
  $color: props.$color || 'black',
}))`
  ${props => props.t === -2 && pXSmall}
  ${props => props.t === -1 && pSmall}
  ${props => props.t === 0 && p}
  ${props => props.t === 1 && pLarge}
  ${props => props.t === 'menuItem' && menuItem}
  ${props => props.t === 'featureTitle' && featureTitle}
  ${props => props.t === 'displayHeadline' && displayHeadline}
  ${props => props.t === 'h1' && h1}
  ${props => props.t === 'h2' && h2}
  ${props => props.t === 'h3' && h3}

  hyphens: ${props => (props.hyphens ? 'auto' : 'none')};
  white-space: ${props => props.whiteSpace};

  a {
    transition: color 0.1s ease-out;

    :hover, :focus {
      color: ${props => theme.colors[theme.colorSchemes[props.$color].hover]}
    }
  }
`;
