import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { useTransitionStore } from './Provider';

import View from './View';

export default ({ children }) => {
  const [
    {
      enterDelay,
      currentLocation,
      views,
      to,
      enterQueue,
      hasEnterStarted,
      hasEntered,
      resetScrollPosition,
    },
    dispatch,
  ] = useTransitionStore();

  const isInitialMount = views.length === 0;
  const isTransitioning = views.length + enterQueue.length > 1;

  useEffect(() => {
    if (to) navigate(to);
  }, [to]);

  useEffect(() => {
    if (currentLocation.key) {
      dispatch({
        type: 'ADD_VIEW',
        view: children,
      });
    }
  }, [currentLocation.key, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let enterTimeout;

    // Start the enter transition after the enter delay has passed
    if (
      !isInitialMount &&
      !hasEnterStarted &&
      typeof enterTimeout === 'undefined'
    ) {
      if (enterDelay > 0) {
        enterTimeout = window.setTimeout(() => {
          dispatch({
            type: 'START_ENTER_TRANSITION',
          });
          enterTimeout = undefined;
        }, enterDelay);
      } else {
        dispatch({
          type: 'START_ENTER_TRANSITION',
        });
      }
    }

    return () =>
      typeof enterTimeout !== 'undefined' && window.clearTimeout(enterTimeout);
  }, [
    currentLocation.key,
    dispatch,
    isInitialMount,
    hasEnterStarted,
    enterDelay,
  ]);

  useEffect(() => {
    if (
      hasEntered &&
      resetScrollPosition &&
      !currentLocation.preventScrollUpdate
    ) {
      window.scrollTo(0, 0);
    }
  }, [hasEntered, resetScrollPosition, currentLocation]);

  return (
    <>
      {isInitialMount ? (
        <View key="initialmount" view={children} action="MOUNT" />
      ) : (
        views.map(({ view }, i) => {
          let action = 'MOUNT';

          if (isTransitioning) {
            action = i === 0 ? 'LEAVE' : 'ENTER';
          }

          return (
            <View
              key={view.props.location.key}
              view={view}
              action={action}
              leaveOverride={currentLocation.leave}
              usualOverride={
                view.props.location.pathname === currentLocation.pathname &&
                currentLocation.usual
              }
              enterOverride={
                view.props.location.pathname === currentLocation.pathname &&
                currentLocation.enter
              }
              preventScrollUpdate={
                view.props.location.pathname === currentLocation.pathname &&
                currentLocation.preventScrollUpdate
              }
            />
          );
        })
      )}
    </>
  );
};
