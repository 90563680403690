import styled from 'styled-components';

import Box from '@/atoms/Box';

import { scale } from '@/styles/utils';

export default styled(Box).attrs({ as: 'button' })`
  position: relative;
  width: ${scale(2.5)};
  height: ${scale(2.5)};
  border: 0;
  background: transparent;
  appearance: none;
  outline: none;
  cursor: pointer;
`;
