const reduce = (state, action) => {
  if (action.type === 'NAVIGATE') {
    return {
      ...state,
      to: action.to,
      preventScrollUpdate: action.preventScrollUpdate,
      enterDelayInterim: action.enterDelay,
      enterInterim: action.enter,
      usualInterim: action.usual,
      leaveInterim: action.leave,
      modeInterim: action.mode,
      keepInterim: action.keep,
    };
  }

  if (action.type === 'UPDATE_LOCATION') {
    return {
      ...state,
      hasEntered: false,
      to: undefined,
      preventScrollUpdate: false,
      enterDelayInterim: null,
      enterInterim: null,
      usualInterim: null,
      leaveInterim: null,
      currentLocation: {
        ...action.location,
        preventScrollUpdate: state.preventScrollUpdate,
        enter: state.enterInterim,
        usual: state.usualInterim,
        leave: state.leaveInterim,
      },
      prevLocation: {
        ...state.currentLocation,
      },
    };
  }

  if (action.type === 'ADD_VIEW') {
    const nextView = { view: action.view };

    // if this is the initial view being mounted, we don't add it to the queue
    if (state.views.length === 0) {
      return {
        ...state,
        views: [nextView],
        enterQueue: [],
        hasEnterStarted: false,
        hasEntered: true,
      };
    }

    // if we haven't started the enter animation we replace the queue
    if (!state.hasEnterStarted) {
      return {
        ...state,
        enterQueue: [nextView],
      };
    }

    // otherwise we just add it to the existing views
    return {
      ...state,
      views: [...state.views, nextView],
    };
  }

  if (action.type === 'START_ENTER_TRANSITION') {
    return {
      ...state,
      views: [...state.views, ...state.enterQueue],
      enterQueue: [],
      hasEnterStarted: true,
    };
  }

  if (action.type === 'HAS_ENTERED') {
    const finalView = state.views[state.views.length - 1];

    // check if this is the last view in the array
    if (
      state.enterQueue.length === 0 &&
      action.locationKey === finalView.view.props.location.key
    ) {
      return {
        ...state,
        views: state.views.slice(-1),
        enterQueue: [],
        hasEnterStarted: false,
        hasEntered: true,
        resetScrollPosition: true,
      };
    }
  }

  return state;
};

export default (state, action) => {
  const nextState = reduce(state, action);

  return nextState;
};
