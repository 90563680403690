export const defaultTransition = {
  enterDelay: 0,
  enter: {
    opacity: 0,
    config: {
      duration: 1000,
      clamp: true,
    },
  },
  usual: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
    config: {
      duration: 200,
      clamp: true,
    },
  },
};

export const fastTransition = {
  enterDelay: 0,
  enter: {
    opacity: 0,
    config: {
      duration: 250,
      clamp: true,
    },
  },
  usual: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
    config: {
      duration: 350,
      clamp: true,
    },
  },
};
